<template>
  <svg
    ref="keyboard"
    id="keyboard"
    width="3214"
    height="1226"
    viewBox="0 0 3214 1226"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-for="key in keys"
      :key="key.dataKey[0]"
      :data-key="key.dataKey[0]"
      :d="key.path"
      :class="{'active': isActiveKey(key.dataKey)}"
    />
  </svg>
</template>


<script setup lang="ts">
import { ref, onMounted } from 'vue';

const props = defineProps<{ activeKey: string }>();

const keys = ref([
  { dataKey: "fn", path: "M169 1016H19C8.50659 1016 0 1024.51 0 1035V1207C0 1217.49 8.50659 1226 19 1226H169C179.493 1226 188 1217.49 188 1207V1035C188 1024.51 179.493 1016 169 1016Z" },
  { dataKey: "ctrl", path: "M393 1016H243C232.507 1016 224 1024.51 224 1035V1207C224 1217.49 232.507 1226 243 1226H393C403.493 1226 412 1217.49 412 1207V1035C412 1024.51 403.493 1016 393 1016Z" },
  { dataKey: "option", path: "M617 1016H467C456.507 1016 448 1024.51 448 1035V1207C448 1217.49 456.507 1226 467 1226H617C627.493 1226 636 1217.49 636 1207V1035C636 1024.51 627.493 1016 617 1016Z" },
  { dataKey: "cmd", path: "M896 1016H691C680.507 1016 672 1024.51 672 1035V1207C672 1217.49 680.507 1226 691 1226H896C906.493 1226 915 1217.49 915 1207V1035C915 1024.51 906.493 1016 896 1016Z" },
  { dataKey: " ", path: "M2016 1016H970C959.507 1016 951 1024.51 951 1035V1207C951 1217.49 959.507 1226 970 1226H2016C2026.49 1226 2035 1217.49 2035 1207V1035C2035 1024.51 2026.49 1016 2016 1016Z" },
  { dataKey: "cmd2", path: "M2300 1016H2095C2084.51 1016 2076 1024.51 2076 1035V1207C2076 1217.49 2084.51 1226 2095 1226H2300C2310.49 1226 2319 1217.49 2319 1207V1035C2319 1024.51 2310.49 1016 2300 1016Z" },
  { dataKey: "option2", path: "M2521 1016H2371C2360.51 1016 2352 1024.51 2352 1035V1207C2352 1217.49 2360.51 1226 2371 1226H2521C2531.49 1226 2540 1217.49 2540 1207V1035C2540 1024.51 2531.49 1016 2521 1016Z" },
  { dataKey: "arrowLeft", path: "M2750 1122H2600C2589.51 1122 2581 1130.51 2581 1141V1207C2581 1217.49 2589.51 1226 2600 1226H2750C2760.49 1226 2769 1217.49 2769 1207V1141C2769 1130.51 2760.49 1122 2750 1122Z" },
  { dataKey: "arrowUp", path: "M2991 1113H2803V1032.09C2803 1022.1 2811.51 1014 2822 1014H2972C2982.49 1014 2991 1022.1 2991 1032.09V1113Z" },
  { dataKey: "arrowDown", path: "M2803 1124H2991V1204.91C2991 1214.9 2982.49 1223 2972 1223H2822C2811.51 1223 2803 1214.9 2803 1204.91V1124Z" },
  { dataKey: "arrowRight", path: "M3193 1122H3043C3032.51 1122 3024 1130.51 3024 1141V1207C3024 1217.49 3032.51 1226 3043 1226H3193C3203.49 1226 3212 1217.49 3212 1207V1141C3212 1130.51 3203.49 1122 3193 1122Z" },
  { dataKey: "shift", path: "M225 796H19C8.50659 796 0 804.507 0 815V959C0 969.493 8.50659 978 19 978H225C235.493 978 244 969.493 244 959V815C244 804.507 235.493 796 225 796Z" },
  { dataKey: "backtick", path: "M448 796H298C287.507 796 279 804.507 279 815V959C279 969.493 287.507 978 298 978H448C458.493 978 467 969.493 467 959V815C467 804.507 458.493 796 448 796Z" },
  { dataKey: "z", path: "M672 796H522C511.507 796 503 804.507 503 815V959C503 969.493 511.507 978 522 978H672C682.493 978 691 969.493 691 959V815C691 804.507 682.493 796 672 796Z" },
  { dataKey: "x", path: "M896 796H746C735.507 796 727 804.507 727 815V959C727 969.493 735.507 978 746 978H896C906.493 978 915 969.493 915 959V815C915 804.507 906.493 796 896 796Z" },
  { dataKey: "c", path: "M1120 796H970C959.507 796 951 804.507 951 815V959C951 969.493 959.507 978 970 978H1120C1130.49 978 1139 969.493 1139 959V815C1139 804.507 1130.49 796 1120 796Z" },
  { dataKey: "v", path: "M1344 796H1194C1183.51 796 1175 804.507 1175 815V959C1175 969.493 1183.51 978 1194 978H1344C1354.49 978 1363 969.493 1363 959V815C1363 804.507 1354.49 796 1344 796Z" },
  { dataKey: "b", path: "M1568 796H1418C1407.51 796 1399 804.507 1399 815V959C1399 969.493 1407.51 978 1418 978H1568C1578.49 978 1587 969.493 1587 959V815C1587 804.507 1578.49 796 1568 796Z" },
  { dataKey: "n", path: "M1792 796H1642C1631.51 796 1623 804.507 1623 815V959C1623 969.493 1631.51 978 1642 978H1792C1802.49 978 1811 969.493 1811 959V815C1811 804.507 1802.49 796 1792 796Z" },
  { dataKey: "m", path: "M2016 796H1866C1855.51 796 1847 804.507 1847 815V959C1847 969.493 1855.51 978 1866 978H2016C2026.49 978 2035 969.493 2035 959V815C2035 804.507 2026.49 796 2016 796Z" },
  { dataKey: [",", "<"], path: "M2240 796H2090C2079.51 796 2071 804.507 2071 815V959C2071 969.493 2079.51 978 2090 978H2240C2250.49 978 2259 969.493 2259 959V815C2259 804.507 2250.49 796 2240 796Z" },
  { dataKey: [".", ">"], path: "M2464 796H2314C2303.51 796 2295 804.507 2295 815V959C2295 969.493 2303.51 978 2314 978H2464C2474.49 978 2483 969.493 2483 959V815C2483 804.507 2474.49 796 2464 796Z" },
  { dataKey: ["/", "?"], path: "M2688 796H2538C2527.51 796 2519 804.507 2519 815V959C2519 969.493 2527.51 978 2538 978H2688C2698.49 978 2707 969.493 2707 959V815C2707 804.507 2698.49 796 2688 796Z" },
  { dataKey: "shift2", path: "M3193 796H2762C2751.51 796 2743 804.507 2743 815V959C2743 969.493 2751.51 978 2762 978H3193C3203.49 978 3212 969.493 3212 959V815C3212 804.507 3203.49 796 3193 796Z" },
  { dataKey: "capslock", path: "M337 577H19C8.50659 577 0 585.507 0 596V740C0 750.493 8.50659 759 19 759H337C347.493 759 356 750.493 356 740V596C356 585.507 347.493 577 337 577Z" },
  { dataKey: "a", path: "M562 577H412C401.507 577 393 585.507 393 596V740C393 750.493 401.507 759 412 759H562C572.493 759 581 750.493 581 740V596C581 585.507 572.493 577 562 577Z" },
  { dataKey: "s", path: "M786 577H636C625.507 577 617 585.507 617 596V740C617 750.493 625.507 759 636 759H786C796.493 759 805 750.493 805 740V596C805 585.507 796.493 577 786 577Z" },
  { dataKey: "d", path: "M1010 577H860C849.507 577 841 585.507 841 596V740C841 750.493 849.507 759 860 759H1010C1020.49 759 1029 750.493 1029 740V596C1029 585.507 1020.49 577 1010 577Z" },
  { dataKey: "f", path: "M1234 577H1084C1073.51 577 1065 585.507 1065 596V740C1065 750.493 1073.51 759 1084 759H1234C1244.49 759 1253 750.493 1253 740V596C1253 585.507 1244.49 577 1234 577Z" },
  { dataKey: "g", path: "M1458 577H1308C1297.51 577 1289 585.507 1289 596V740C1289 750.493 1297.51 759 1308 759H1458C1468.49 759 1477 750.493 1477 740V596C1477 585.507 1468.49 577 1458 577Z" },
  { dataKey: "h", path: "M1682 577H1532C1521.51 577 1513 585.507 1513 596V740C1513 750.493 1521.51 759 1532 759H1682C1692.49 759 1701 750.493 1701 740V596C1701 585.507 1692.49 577 1682 577Z" },
  { dataKey: "j", path: "M1906 577H1756C1745.51 577 1737 585.507 1737 596V740C1737 750.493 1745.51 759 1756 759H1906C1916.49 759 1925 750.493 1925 740V596C1925 585.507 1916.49 577 1906 577Z" },
  { dataKey: "k", path: "M2130 577H1980C1969.51 577 1961 585.507 1961 596V740C1961 750.493 1969.51 759 1980 759H2130C2140.49 759 2149 750.493 2149 740V596C2149 585.507 2140.49 577 2130 577Z" },
  { dataKey: "l", path: "M2354 577H2204C2193.51 577 2185 585.507 2185 596V740C2185 750.493 2193.51 759 2204 759H2354C2364.49 759 2373 750.493 2373 740V596C2373 585.507 2364.49 577 2354 577Z" },
  { dataKey: [";", ":"], path: "M2578 577H2428C2417.51 577 2409 585.507 2409 596V740C2409 750.493 2417.51 759 2428 759H2578C2588.49 759 2597 750.493 2597 740V596C2597 585.507 2588.49 577 2578 577Z" },
  { dataKey: [`'`, `"`], path: "M2802 577H2652C2641.51 577 2633 585.507 2633 596V740C2633 750.493 2641.51 759 2652 759H2802C2812.49 759 2821 750.493 2821 740V596C2821 585.507 2812.49 577 2802 577Z" },
  { dataKey: [`/`, "|"], path: "M3026 577H2876C2865.51 577 2857 585.507 2857 596V740C2857 750.493 2865.51 759 2876 759H3026C3036.49 759 3045 750.493 3045 740V596C3045 585.507 3036.49 577 3026 577Z" },
  { dataKey: "tab", path: "M279 359H19C8.50659 359 0 367.507 0 378V522C0 532.493 8.50659 541 19 541H279C289.493 541 298 532.493 298 522V378C298 367.507 289.493 359 279 359Z" },
  { dataKey: "q", path: "M505 360H355C344.507 360 336 368.507 336 379V523C336 533.493 344.507 542 355 542H505C515.493 542 524 533.493 524 523V379C524 368.507 515.493 360 505 360Z" },
  { dataKey: "w", path: "M729 360H579C568.507 360 560 368.507 560 379V523C560 533.493 568.507 542 579 542H729C739.493 542 748 533.493 748 523V379C748 368.507 739.493 360 729 360Z" },
  { dataKey: "e", path: "M953 360H803C792.507 360 784 368.507 784 379V523C784 533.493 792.507 542 803 542H953C963.493 542 972 533.493 972 523V379C972 368.507 963.493 360 953 360Z" },
  { dataKey: "r", path: "M1177 360H1027C1016.51 360 1008 368.507 1008 379V523C1008 533.493 1016.51 542 1027 542H1177C1187.49 542 1196 533.493 1196 523V379C1196 368.507 1187.49 360 1177 360Z" },
  { dataKey: "t", path: "M1401 360H1251C1240.51 360 1232 368.507 1232 379V523C1232 533.493 1240.51 542 1251 542H1401C1411.49 542 1420 533.493 1420 523V379C1420 368.507 1411.49 360 1401 360Z" },
  { dataKey: "y", path: "M1625 360H1475C1464.51 360 1456 368.507 1456 379V523C1456 533.493 1464.51 542 1475 542H1625C1635.49 542 1644 533.493 1644 523V379C1644 368.507 1635.49 360 1625 360Z" },
  { dataKey: "u", path: "M1849 360H1699C1688.51 360 1680 368.507 1680 379V523C1680 533.493 1688.51 542 1699 542H1849C1859.49 542 1868 533.493 1868 523V379C1868 368.507 1859.49 360 1849 360Z" },
  { dataKey: "i", path: "M2073 360H1923C1912.51 360 1904 368.507 1904 379V523C1904 533.493 1912.51 542 1923 542H2073C2083.49 542 2092 533.493 2092 523V379C2092 368.507 2083.49 360 2073 360Z" },
  { dataKey: "o", path: "M2297 360H2147C2136.51 360 2128 368.507 2128 379V523C2128 533.493 2136.51 542 2147 542H2297C2307.49 542 2316 533.493 2316 523V379C2316 368.507 2307.49 360 2297 360Z" },
  { dataKey: "p", path: "M2521 360H2371C2360.51 360 2352 368.507 2352 379V523C2352 533.493 2360.51 542 2371 542H2521C2531.49 542 2540 533.493 2540 523V379C2540 368.507 2531.49 360 2521 360Z" },
  { dataKey: ["[", "{"], path: "M2745 360H2595C2584.51 360 2576 368.507 2576 379V523C2576 533.493 2584.51 542 2595 542H2745C2755.49 542 2764 533.493 2764 523V379C2764 368.507 2755.49 360 2745 360Z" },
  { dataKey: ["]", "}"], path: "M2969 360H2819C2808.51 360 2800 368.507 2800 379V523C2800 533.493 2808.51 542 2819 542H2969C2979.49 542 2988 533.493 2988 523V379C2988 368.507 2979.49 360 2969 360Z" },
  { dataKey: "return", path: "M3050 360C3039.51 360 3031 368.507 3031 379V523C3031 533.493 3039.51 542 3050 542H3057H3065.38H3071C3077.63 542 3083 547.373 3083 554V579C3083 580.036 3082.87 581.041 3082.62 582H3083V736C3083 746.493 3091.51 755 3102 755H3193C3203.49 755 3212 746.493 3212 736V523V471V379C3212 368.507 3203.49 360 3193 360H3050Z" },
  { dataKey: "sign", path: "M169 141H19C8.50659 141 0 149.507 0 160V304C0 314.493 8.50659 323 19 323H169C179.493 323 188 314.493 188 304V160C188 149.507 179.493 141 169 141Z" },
  { dataKey: ["1", "!"], path: "M393 141H243C232.507 141 224 149.507 224 160V304C224 314.493 232.507 323 243 323H393C403.493 323 412 314.493 412 304V160C412 149.507 403.493 141 393 141Z" },
  { dataKey: ["2", "@"], path: "M617 141H467C456.507 141 448 149.507 448 160V304C448 314.493 456.507 323 467 323H617C627.493 323 636 314.493 636 304V160C636 149.507 627.493 141 617 141Z" },
  { dataKey: ["3", "#"], path: "M841 141H691C680.507 141 672 149.507 672 160V304C672 314.493 680.507 323 691 323H841C851.493 323 860 314.493 860 304V160C860 149.507 851.493 141 841 141Z" },
  { dataKey: ["4", "$"], path: "M1065 141H915C904.507 141 896 149.507 896 160V304C896 314.493 904.507 323 915 323H1065C1075.49 323 1084 314.493 1084 304V160C1084 149.507 1075.49 141 1065 141Z" },
  { dataKey: ["5", "%"], path: "M1289 141H1139C1128.51 141 1120 149.507 1120 160V304C1120 314.493 1128.51 323 1139 323H1289C1299.49 323 1308 314.493 1308 304V160C1308 149.507 1299.49 141 1289 141Z" },
  { dataKey: "6", path: "M1513 141H1363C1352.51 141 1344 149.507 1344 160V304C1344 314.493 1352.51 323 1363 323H1513C1523.49 323 1532 314.493 1532 304V160C1532 149.507 1523.49 141 1513 141Z" },
  { dataKey: ["7", "&"], path: "M1737 141H1587C1576.51 141 1568 149.507 1568 160V304C1568 314.493 1576.51 323 1587 323H1737C1747.49 323 1756 314.493 1756 304V160C1756 149.507 1747.49 141 1737 141Z" },
  { dataKey: "8", path: "M1961 141H1811C1800.51 141 1792 149.507 1792 160V304C1792 314.493 1800.51 323 1811 323H1961C1971.49 323 1980 314.493 1980 304V160C1980 149.507 1971.49 141 1961 141Z" },
  { dataKey: ["9", "("], path: "M2185 141H2035C2024.51 141 2016 149.507 2016 160V304C2016 314.493 2024.51 323 2035 323H2185C2195.49 323 2204 314.493 2204 304V160C2204 149.507 2195.49 141 2185 141Z" },
  { dataKey: ["0", ")"], path: "M2409 141H2259C2248.51 141 2240 149.507 2240 160V304C2240 314.493 2248.51 323 2259 323H2409C2419.49 323 2428 314.493 2428 304V160C2428 149.507 2419.49 141 2409 141Z" },
  { dataKey: ["-", "_"], path: "M2633 141H2483C2472.51 141 2464 149.507 2464 160V304C2464 314.493 2472.51 323 2483 323H2633C2643.49 323 2652 314.493 2652 304V160C2652 149.507 2643.49 141 2633 141Z" },
  { dataKey: ["=", "+"], path: "M2857 141H2707C2696.51 141 2688 149.507 2688 160V304C2688 314.493 2696.51 323 2707 323H2857C2867.49 323 2876 314.493 2876 304V160C2876 149.507 2867.49 141 2857 141Z" },
  { dataKey: "delete", path: "M3195 141H2931C2920.51 141 2912 149.507 2912 160V304C2912 314.493 2920.51 323 2931 323H3195C3205.49 323 3214 314.493 3214 304V160C3214 149.507 3205.49 141 3195 141Z" },
  { dataKey: "esc", path: "M179 0H19C8.50659 0 0 8.50659 0 19V85C0 95.4934 8.50659 104 19 104H179C189.493 104 198 95.4934 198 85V19C198 8.50659 189.493 0 179 0Z" },
  { dataKey: "f1", path: "M411 0H251C240.507 0 232 8.50659 232 19V85C232 95.4934 240.507 104 251 104H411C421.493 104 430 95.4934 430 85V19C430 8.50659 421.493 0 411 0Z" },
  { dataKey: "f2", path: "M643 0H483C472.507 0 464 8.50659 464 19V85C464 95.4934 472.507 104 483 104H643C653.493 104 662 95.4934 662 85V19C662 8.50659 653.493 0 643 0Z" },
  { dataKey: "f3", path: "M875 0H715C704.507 0 696 8.50659 696 19V85C696 95.4934 704.507 104 715 104H875C885.493 104 894 95.4934 894 85V19C894 8.50659 885.493 0 875 0Z" },
  { dataKey: "f4", path: "M1107 0H947C936.507 0 928 8.50659 928 19V85C928 95.4934 936.507 104 947 104H1107C1117.49 104 1126 95.4934 1126 85V19C1126 8.50659 1117.49 0 1107 0Z" },
  { dataKey: "f5", path: "M1339 0H1179C1168.51 0 1160 8.50659 1160 19V85C1160 95.4934 1168.51 104 1179 104H1339C1349.49 104 1358 95.4934 1358 85V19C1358 8.50659 1349.49 0 1339 0Z" },
  { dataKey: "f6", path: "M1571 0H1411C1400.51 0 1392 8.50659 1392 19V85C1392 95.4934 1400.51 104 1411 104H1571C1581.49 104 1590 95.4934 1590 85V19C1590 8.50659 1581.49 0 1571 0Z" },
  { dataKey: "f7", path: "M1803 0H1643C1632.51 0 1624 8.50659 1624 19V85C1624 95.4934 1632.51 104 1643 104H1803C1813.49 104 1822 95.4934 1822 85V19C1822 8.50659 1813.49 0 1803 0Z" },
  { dataKey: "f8", path: "M2035 0H1875C1864.51 0 1856 8.50659 1856 19V85C1856 95.4934 1864.51 104 1875 104H2035C2045.49 104 2054 95.4934 2054 85V19C2054 8.50659 2045.49 0 2035 0Z" },
  { dataKey: "f9", path: "M2267 0H2107C2096.51 0 2088 8.50659 2088 19V85C2088 95.4934 2096.51 104 2107 104H2267C2277.49 104 2286 95.4934 2286 85V19C2286 8.50659 2277.49 0 2267 0Z" },
  { dataKey: "f10", path: "M2499 0H2339C2328.51 0 2320 8.50659 2320 19V85C2320 95.4934 2328.51 104 2339 104H2499C2509.49 104 2518 95.4934 2518 85V19C2518 8.50659 2509.49 0 2499 0Z" },
  { dataKey: "f11", path: "M2731 0H2571C2560.51 0 2552 8.50659 2552 19V85C2552 95.4934 2560.51 104 2571 104H2731C2741.49 104 2750 95.4934 2750 85V19C2750 8.50659 2741.49 0 2731 0Z" },
  { dataKey: "f12", path: "M2963 0H2803C2792.51 0 2784 8.50659 2784 19V85C2784 95.4934 2792.51 104 2803 104H2963C2973.49 104 2982 95.4934 2982 85V19C2982 8.50659 2973.49 0 2963 0Z" },
  { dataKey: "power", path: "M3195 0H3035C3024.51 0 3016 8.50659 3016 19V85C3016 95.4934 3024.51 104 3035 104H3195C3205.49 104 3214 95.4934 3214 85V19C3214 8.50659 3205.49 0 3195 0Z" }

]);

const isActiveKey = (keyData: string | string[]) => {
  if (Array.isArray(keyData)) {
    return keyData.includes(props.activeKey);
  }
  return keyData === props.activeKey;
};

</script>


<style lang="scss" scoped>

#keyboard {
  width: 100%;
  height: auto;
}

path {
  fill: #009d90;
  opacity: .06;
  //transition: all .03s;

  &.active {
    opacity: .7;
  }
}

.v-theme--dark {
  path {
    fill: #f0f;
    opacity: .06;

    &.active {
      opacity: .4;
    }
  }
}
.v-theme--light {
  path {
    fill: #cd74f1;
    opacity: .06;

    &.active {
      opacity: .4;
    }
  }
}
</style>
